





















































































































































































































































































@import "../../styles/public";
@import "../../styles/state";

.brief-page {
  margin: 0;
  font: 13px/1.5 "Microsoft YaHei", "Helvetica Neue", "Sans-Serif";
  min-height: 960px;
  // min-width: 600px;
  .bannerTabbar {
    font-size: 1rem;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .introduced-wrapper {
    margin: 7rem auto;
    text-align: center;
    .commerceText {
      margin-bottom: 5rem;
      font-size: 2rem;
      width: 100rem;
      text-align: left;
    }
    .commerceText.address {
      margin: 2rem 0;
    }
    // 头部样式
    .title {
      width: 100rem;
      padding: 2.5rem;
      border: 2px solid #f7d580;
      border-radius: 1rem;
      font-size: 2.2rem;
      text-align: center;
      position: relative;
      .abstractClass {
        position: absolute;
        background-color: #fff;
        padding: 0 2rem;
        // margin: 0 2rem;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    // 内容文字
    .textCenter {
      width: 100rem;
      padding: 2.5rem;
      border: 2px solid #f7d580;
      border-radius: 1rem;
      font-size: 1.8rem;
      text-align: left;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .article {
      width: 90rem;
      border: 1px solid #f32e4c;
      font-size: 1.6rem;
      padding: 4rem 2rem;
      margin-top: 8rem;
      text-align: left;
      position: relative;
      .chapter {
        font-size: 2.5rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
      }
      .line {
        margin-bottom: 4rem;
        p {
          margin: 0;
          height: 0.6rem;
          color: #f32e4c;
        }
      }
      .text {
        font-size: 1.8rem;
        line-height: 3rem;
        margin: 0.5rem 0;
      }
    }

    // 商会发起企业及负责人
    .head {
      width: 100rem;
      text-align: left;
      font-size: 1.8rem;
      margin: 6rem 0 1rem;
    }

    // 表格
    .Modbus {
      width: 100rem;
      font-size: 1.8rem;
      border: solid #ccc 1px; /*设置表格外边框*/
      border-radius: 10px; /*设置表格外边框圆角*/
    }

    .Modbus td,
    .Modbus th {
      border-right: solid #ccc 1px; /*设置表格单元格外边框，只设置右下边框*/
      border-bottom: solid #ccc 1px;
      padding: 10px; /*设置单元格内边距，单元格内容显得不拥挤*/
      text-align: left; /*单元格内容居中*/
    }
    .Modbus td:last-child,
    .Modbus th:last-child {
      border-right: none; /*去掉表格每一行最后一列的右边框*/
    }

    .Modbus tr:last-child td {
      border-bottom: none; /*去掉最后一行中所有单元格的下边框*/
    }
    /*鼠标移动至单元格，单元格高亮显示*/
    .Modbus tr:hover {
      background: #fbf8e9;
    }
    /*分别设置每一行中单元格的宽度*/
    .company {
      width: 70%;
    }
    .position {
      width: 15%;
    }
    .compellation {
      width: 15%;
    }

    // 组织架构
    .architecture {
      width: 100rem;
      text-align: left;
      margin: 4rem 0 1rem;
      font-size: 2.2rem;
    }

    @media screen and (min-width: 768px) {
      //pc
      .oficeAddress {
        width: 50%;
        height: 43rem;
        margin-top: 5rem;
        padding: 3rem;
        border: 2px solid #681107;
        font-size: 1.8rem;
        text-align: center;
      }
    }
    @media screen and (min-width: 200px) and (max-width: 768px) {
      //手机
      .oficeAddress {
        width: 90%;
        height: 43rem;
        margin-top: 5rem;
        padding: 3rem;
        border: 2px solid #681107;
        font-size: 1.8rem;
        text-align: center;
      }
    }
  }

  .contact-us {
    max-width: 1160px;
    margin: 40px auto;
    & > p {
      font-size: 24px;
    }
    .contact-us-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .map-container {
        overflow: hidden;
        width: 100%;
        height: 400px;
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  // 商会简介
  .brief-page {
    .introduced-wrapper {
      .commerceText,
      .title,
      .textCenter,
      .head,
      .Modbus,
      .architecture,
      .contactUs {
        width: 75rem;
      }
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  // 商会简介
  .brief-page {
    .introduced-wrapper {
      .commerceText,
      .title,
      .textCenter,
      .head,
      .Modbus,
      .architecture,
      .contactUs {
        width: 55rem;
      }
    }
  }
}

@media screen and (min-width: 200px) and (max-width: 768px) {
  // 商会简介
  .brief-page {
    .introduced-wrapper {
      .commerceText,
      .title,
      .textCenter,
      .head,
      .architecture,
      .contactUs {
        width: 40rem;
        font-size: 1.6rem;
      }
      .Modbus {
        width: 40rem;
        font-size: 1.4rem;

        .company {
          width: 64%;
        }
        .position {
          width: 18%;
        }
        .compellation {
          width: 18%;
        }
      }
      .title {
        .abstractClass {
          img {
            width: 3.5rem;
          }
        }
      }
      .architecture {
        img {
          width: 3.5rem;
        }
      }
    }
  }
}

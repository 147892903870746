





































































































































































































































































.rightNav {
  font-size: 12px;
  position: fixed;
  z-index: 100;
  right: 0;
  top: 20rem;
  a {
    text-decoration: none;
  }
  .tips {
    margin-top: -0.6rem;
    color: red;
  }
  .rightNavFrame {
    ul {
      list-style-type: none;
      li {
        background-color: #ffffff;
        box-shadow: 0px 4px 8px 0px rgba(186, 210, 246, 0.28);
        padding: 1.33rem;
        margin-bottom: 0.67rem;
        width: 5.33rem;
        height: 9.58rem;
        font-size: 1.33rem;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #657687;
        img {
          width: 2.33rem;
          height: 2.33rem;
          margin-bottom: 0.85rem;
        }
        .default {
          cursor: pointer;
        }
      }
      .rightNavConsult {
        position: relative;
        .rightNavConsultFrame {
          background: white;
          border-radius: 0.33rem;
          position: absolute;
          top: -20rem;
          right: 7.45rem;
          width: 44rem;
          height: 54rem;
          text-align: center;
          .consultImg {
            position: relative;
            width: 100%;

            img {
              -webkit-border-top-left-radius: 0.33rem;
              -webkit-border-top-right-radius: 0.33rem;
              width: 100%;
              height: 10.8rem;
            }
            .contactClose {
              width: 0.84rem;
              height: 0.84rem;
              cursor: pointer;
              position: absolute;
              right: 2rem;
              top: 1.5rem;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .contactTag {
              position: absolute;
              display: inline-block;
              right: -1.5rem;
              top: 24rem;
              border: 0.75rem solid;
              border-color: transparent transparent transparent white;
            }
          }
          .consultWrite {
            background: white;
            padding-top: 2rem;
            position: relative;
            .textareaFrame {
              position: relative;
              width: 36rem;
              height: 12rem;
              border-radius: 0.33rem;
              border: 0.08rem solid #c0c0c0;
              margin: 0 auto;
              textarea {
                width: 35.6rem;
                height: 9.4rem;
                resize: none;
                padding: 0 0.5rem;
                border: transparent;
              }
              span {
                position: absolute;
                font-size: 1rem;
                bottom: 0.4rem;
                right: 1rem;
              }
              span:last-child {
                color: red;
                bottom: -2rem;
                left: -71%;
              }
            }
            .inputNameFrame {
              position: relative;
              span {
                font-size: 1rem;
                position: absolute;
                color: red;
                left: 4.5rem;
                bottom: -2rem;
              }
            }
            .inputPhoneFrame {
              position: relative;
              span {
                font-size: 1rem;
                position: absolute;
                color: red;
                left: 4.5rem;
                bottom: -2rem;
              }
            }
            .inputEmailFrame {
              position: relative;
              span {
                font-size: 1rem;
                position: absolute;
                color: red;
                left: 4.5rem;
                bottom: -2rem;
              }
            }
            input {
              width: 36rem;
              height: 4rem;
              margin-top: 2.33rem;
              text-indent: 1rem;
              border-radius: 0.33rem;
              border: 0.08rem solid #c0c0c0;
              border-radius: 0.33rem;
            }
            button {
              width: 21.25rem;
              height: 4rem;
              background: #1784ff;
              border-radius: 0.33rem;
              color: white;
              border: 0;
              margin-top: 2.33rem;
              border-radius: 0.33rem;
            }
            .consultSuccessFrame {
              top: -11.65rem;
              position: absolute;
              width: 100%;
              height: 54rem;
              .successPosition {
                position: relative;
                width: 100%;
                height: 100%;
                .consultSuccess {
                  position: absolute;
                  z-index: 0;
                  width: 100%;
                  height: 100%;
                  background: #000;
                  opacity: 0.3;
                }
                .successFrame {
                  position: relative;
                  width: 70%;
                  height: 50%;
                  top: 25%;
                  background: #ffffff;
                  margin: auto;
                  z-index: 100;
                  border-radius: 1rem;
                  padding-top: 9.6rem;
                  .successImg {
                    position: absolute;
                    top: -9.9rem;
                    right: 5rem;
                    img {
                      width: 20rem;
                      height: 19.8rem;
                    }
                  }
                  .successContent {
                    p {
                      font-size: 2.2rem;
                      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                      font-weight: 500;
                      color: #333333;
                    }
                    p:nth-child(2) {
                      font-size: 1.6rem;
                      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                      font-weight: 400;
                      color: #999999;
                    }
                    button {
                      font-size: 2rem;
                      height: 4.8rem;
                      border-radius: 4.8rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .rightNavRegister {
        position: relative;
        .rightNavRegisterFrame {
          cursor: default;
          background-color: #ffffff;
          box-shadow: 0px 4px 8px 0px rgba(186, 210, 246, 0.28);
          border-radius: 1.67rem;
          position: absolute;
          right: 7.45rem;
          top: -30rem;
          width: 54.92rem;
          height: 63.33rem;
          padding: 6.5rem 6.17rem 6.75rem 6.08rem;
          .title {
            text-align: center;
            font-size: 3.17rem;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #333333;
            position: relative;
            .registerClose {
              position: absolute;
              right: -3rem;
              top: -4.67rem;
              width: 0.84rem;
              height: 0.84rem;
              cursor: pointer;
              img {
                width: 0.84rem;
                height: 0.84rem;
              }
            }
            .registerTag {
              display: inline-block;
              position: absolute;
              right: -7.65rem;
              top: 28.08rem;
              border: 0.75rem solid;
              border-color: transparent transparent transparent white;
            }
          }
          .wirteFrame {
            margin: 5rem 0 4.42rem 0;
            input {
              border-radius: 0.33rem;
              height: 4rem;
              border: 0.08rem solid #c0c0c0;
              text-indent: 1.33rem;
              vertical-align: middle;
            }
            // 提示字体适配
            input::-webkit-input-placeholder {
              /* WebKit browsers */
              font-family: SourceHanSansCN-Regular;
              font-size: 1.5rem;
              color: #999999;
              text-align: justify;
            }
            input:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              font-family: SourceHanSansCN-Regular;
              font-size: 1.5rem;
              color: #999999;
              text-align: justify;
            }
            input::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              font-family: SourceHanSansCN-Regular;
              font-size: 1.5rem;
              color: #999999;
              text-align: justify;
            }
            input:-ms-input-placeholder {
              /* Internet Explorer 10+ */
              font-family: SourceHanSansCN-Regular;
              font-size: 1.5rem;
              color: #999999;
              text-align: justify;
            }
            .longtext {
              width: 42.67rem;
            }
            .phone {
              width: 36rem;
            }
            .code {
              width: 22.3rem;
            }
            .lower {
              height: 4rem;
              margin-bottom: 2.33rem;
              line-height: 3.7rem;
              .beforePhone {
                display: inline-block;
                box-sizing: border-box;
                border: 0.08rem solid #c0c0c0;
                border-radius: 0.33rem;
                width: 6rem;
                height: 4rem;
                text-align: center;
                margin: 0 0.67rem 0 0;
              }
              .getImg {
                img {
                  box-sizing: border-box;
                  border-radius: 0.33rem;
                  width: 17rem;
                  height: 4rem;
                  font-size: 1.5rem;
                  margin: 0 0 0 3.25rem;
                  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                  font-weight: 400;
                  cursor: pointer;
                }
              }
            }
            .lower1 {
              line-height: 3.5rem;
            }
            .difflower {
              margin-bottom: 2.42rem;
              position: relative;
              .getCode {
                display: inline-block;
                box-sizing: border-box;
                border-radius: 0.33rem;
                text-align: center;
                margin: 0 0.67rem 0 0;
                width: 17rem;
                height: 4rem;
                background: rgba(61, 152, 255, 0.2);
                border-radius: 0.33rem;
                border: 0.08rem solid #1784ff;
                font-size: 1.5rem;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #459cff;
                line-height: 3.4rem;
                margin-left: 3.25rem;
                cursor: pointer;
              }
              .time {
                display: inline-block;
                box-sizing: border-box;
                border-radius: 0.33rem;
                text-align: center;
                margin: 0 0.67rem 0 0;
                width: 17rem;
                height: 4rem;
                border-radius: 0.33rem;
                border: 0.08rem solid #c0c0c0;
                font-size: 1.5rem;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                margin-left: 3.25rem;
                line-height: 3.4rem;
                cursor: pointer;
              }
              .tips {
                position: absolute;
                bottom: -2rem;
              }
            }
            .checkbox {
              font-size: 1.17rem;
              height: 1.33rem;
              line-height: 1.333rem;
              .check {
                height: 1.33rem;
                width: 1.33rem;
                margin: 0;
              }
              span {
                display: inline-block;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #333333;
                margin-left: 2rem;
              }
              a {
                color: #459cff;
              }
              .tips {
                margin-top: 0.6rem;
              }
            }
          }
          button {
            background: #1784ff;
            width: 21.25rem;
            height: 4rem;
            color: #fff;
            font-size: 1.83rem;
            font-family: SourceHanSansCN-Medium;
            border-radius: 0.33rem;
            border: none;
          }
        }
      }
      .rightNavContact {
        position: relative;
        .rightNavContactFrame {
          cursor: default;
          position: absolute;
          background-color: #ffffff;
          box-shadow: 0px 4px 8px 0px rgba(186, 210, 246, 0.28);
          border-radius: 1.67rem;
          position: absolute;
          right: 7.45rem;
          top: -6.5rem;
          width: 24.33rem;
          height: 22.33rem;
          padding: 3.33rem 5.2rem 4.5rem 3.92rem;
          .title {
            font-size: 1.33rem;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #666666;
            position: relative;
            .contactClose {
              position: absolute;
              top: -1.58rem;
              right: -3.5rem;
              width: 0.84rem;
              height: 0.84rem;
              cursor: pointer;
              img {
                width: 0.84rem;
                height: 0.84rem;
              }
            }
            .contactTag {
              position: absolute;
              display: inline-block;
              right: -6.65rem;
              top: 8.08rem;
              border: 0.75rem solid;
              border-color: transparent transparent transparent white;
            }
            .phone {
              font-size: 2rem;
              font-family: SourceHanSansCN-Bold, SourceHanSansCN;
              font-weight: bold;
              color: #357def;
              display: inline-block;
              margin-bottom: 0.75rem;
            }
            .time {
              font-size: 1.33rem;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #666666;
              display: inline-block;
              margin-bottom: 0.75rem;
            }
            .code {
              img {
                width: 6.67rem;
                height: 6.67rem;
              }
              img:nth-of-type(1) {
                margin-right: 1.8rem;
              }
            }
          }
        }
      }
      .rightNavLast {
        font-size: 0;
        width: 5.33rem;
        height: 5.33rem;
        text-align: center;
        margin: 0;
        .default {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }
      }
    }
  }
}

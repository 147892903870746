.rightNav {
  font-size: 12px;
  position: fixed;
  z-index: 100;
  right: 0;
  top: 20rem;
}
.rightNav a {
  text-decoration: none;
}
.rightNav .tips {
  margin-top: -0.6rem;
  color: red;
}
.rightNav .rightNavFrame ul {
  list-style-type: none;
}
.rightNav .rightNavFrame ul li {
  background-color: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(186, 210, 246, 0.28);
  padding: 1.33rem;
  margin-bottom: 0.67rem;
  width: 5.33rem;
  height: 9.58rem;
  font-size: 1.33rem;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #657687;
}
.rightNav .rightNavFrame ul li img {
  width: 2.33rem;
  height: 2.33rem;
  margin-bottom: 0.85rem;
}
.rightNav .rightNavFrame ul li .default {
  cursor: pointer;
}
.rightNav .rightNavFrame ul .rightNavConsult {
  position: relative;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame {
  background: white;
  border-radius: 0.33rem;
  position: absolute;
  top: -20rem;
  right: 7.45rem;
  width: 44rem;
  height: 54rem;
  text-align: center;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultImg {
  position: relative;
  width: 100%;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultImg img {
  -webkit-border-top-left-radius: 0.33rem;
  -webkit-border-top-right-radius: 0.33rem;
  width: 100%;
  height: 10.8rem;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultImg .contactClose {
  width: 0.84rem;
  height: 0.84rem;
  cursor: pointer;
  position: absolute;
  right: 2rem;
  top: 1.5rem;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultImg .contactClose img {
  width: 100%;
  height: 100%;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultImg .contactTag {
  position: absolute;
  display: inline-block;
  right: -1.5rem;
  top: 24rem;
  border: 0.75rem solid;
  border-color: transparent transparent transparent white;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite {
  background: white;
  padding-top: 2rem;
  position: relative;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite .textareaFrame {
  position: relative;
  width: 36rem;
  height: 12rem;
  border-radius: 0.33rem;
  border: 0.08rem solid #c0c0c0;
  margin: 0 auto;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite .textareaFrame textarea {
  width: 35.6rem;
  height: 9.4rem;
  resize: none;
  padding: 0 0.5rem;
  border: transparent;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite .textareaFrame span {
  position: absolute;
  font-size: 1rem;
  bottom: 0.4rem;
  right: 1rem;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite .textareaFrame span:last-child {
  color: red;
  bottom: -2rem;
  left: -71%;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite .inputNameFrame {
  position: relative;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite .inputNameFrame span {
  font-size: 1rem;
  position: absolute;
  color: red;
  left: 4.5rem;
  bottom: -2rem;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite .inputPhoneFrame {
  position: relative;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite .inputPhoneFrame span {
  font-size: 1rem;
  position: absolute;
  color: red;
  left: 4.5rem;
  bottom: -2rem;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite .inputEmailFrame {
  position: relative;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite .inputEmailFrame span {
  font-size: 1rem;
  position: absolute;
  color: red;
  left: 4.5rem;
  bottom: -2rem;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite input {
  width: 36rem;
  height: 4rem;
  margin-top: 2.33rem;
  text-indent: 1rem;
  border: 0.08rem solid #c0c0c0;
  border-radius: 0.33rem;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite button {
  width: 21.25rem;
  height: 4rem;
  background: #1784ff;
  color: white;
  border: 0;
  margin-top: 2.33rem;
  border-radius: 0.33rem;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite .consultSuccessFrame {
  top: -11.65rem;
  position: absolute;
  width: 100%;
  height: 54rem;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite .consultSuccessFrame .successPosition {
  position: relative;
  width: 100%;
  height: 100%;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite .consultSuccessFrame .successPosition .consultSuccess {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.3;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite .consultSuccessFrame .successPosition .successFrame {
  position: relative;
  width: 70%;
  height: 50%;
  top: 25%;
  background: #ffffff;
  margin: auto;
  z-index: 100;
  border-radius: 1rem;
  padding-top: 9.6rem;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite .consultSuccessFrame .successPosition .successFrame .successImg {
  position: absolute;
  top: -9.9rem;
  right: 5rem;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite .consultSuccessFrame .successPosition .successFrame .successImg img {
  width: 20rem;
  height: 19.8rem;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite .consultSuccessFrame .successPosition .successFrame .successContent p {
  font-size: 2.2rem;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #333333;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite .consultSuccessFrame .successPosition .successFrame .successContent p:nth-child(2) {
  font-size: 1.6rem;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #999999;
}
.rightNav .rightNavFrame ul .rightNavConsult .rightNavConsultFrame .consultWrite .consultSuccessFrame .successPosition .successFrame .successContent button {
  font-size: 2rem;
  height: 4.8rem;
  border-radius: 4.8rem;
}
.rightNav .rightNavFrame ul .rightNavRegister {
  position: relative;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame {
  cursor: default;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(186, 210, 246, 0.28);
  border-radius: 1.67rem;
  position: absolute;
  right: 7.45rem;
  top: -30rem;
  width: 54.92rem;
  height: 63.33rem;
  padding: 6.5rem 6.17rem 6.75rem 6.08rem;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .title {
  text-align: center;
  font-size: 3.17rem;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #333333;
  position: relative;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .title .registerClose {
  position: absolute;
  right: -3rem;
  top: -4.67rem;
  width: 0.84rem;
  height: 0.84rem;
  cursor: pointer;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .title .registerClose img {
  width: 0.84rem;
  height: 0.84rem;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .title .registerTag {
  display: inline-block;
  position: absolute;
  right: -7.65rem;
  top: 28.08rem;
  border: 0.75rem solid;
  border-color: transparent transparent transparent white;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame {
  margin: 5rem 0 4.42rem 0;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame input {
  border-radius: 0.33rem;
  height: 4rem;
  border: 0.08rem solid #c0c0c0;
  text-indent: 1.33rem;
  vertical-align: middle;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame input::-webkit-input-placeholder {
  /* WebKit browsers */
  font-family: SourceHanSansCN-Regular;
  font-size: 1.5rem;
  color: #999999;
  text-align: justify;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-family: SourceHanSansCN-Regular;
  font-size: 1.5rem;
  color: #999999;
  text-align: justify;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-family: SourceHanSansCN-Regular;
  font-size: 1.5rem;
  color: #999999;
  text-align: justify;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  font-family: SourceHanSansCN-Regular;
  font-size: 1.5rem;
  color: #999999;
  text-align: justify;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame .longtext {
  width: 42.67rem;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame .phone {
  width: 36rem;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame .code {
  width: 22.3rem;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame .lower {
  height: 4rem;
  margin-bottom: 2.33rem;
  line-height: 3.7rem;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame .lower .beforePhone {
  display: inline-block;
  box-sizing: border-box;
  border: 0.08rem solid #c0c0c0;
  border-radius: 0.33rem;
  width: 6rem;
  height: 4rem;
  text-align: center;
  margin: 0 0.67rem 0 0;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame .lower .getImg img {
  box-sizing: border-box;
  border-radius: 0.33rem;
  width: 17rem;
  height: 4rem;
  font-size: 1.5rem;
  margin: 0 0 0 3.25rem;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  cursor: pointer;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame .lower1 {
  line-height: 3.5rem;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame .difflower {
  margin-bottom: 2.42rem;
  position: relative;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame .difflower .getCode {
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  margin: 0 0.67rem 0 0;
  width: 17rem;
  height: 4rem;
  background: rgba(61, 152, 255, 0.2);
  border-radius: 0.33rem;
  border: 0.08rem solid #1784ff;
  font-size: 1.5rem;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #459cff;
  line-height: 3.4rem;
  margin-left: 3.25rem;
  cursor: pointer;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame .difflower .time {
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  margin: 0 0.67rem 0 0;
  width: 17rem;
  height: 4rem;
  border-radius: 0.33rem;
  border: 0.08rem solid #c0c0c0;
  font-size: 1.5rem;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  margin-left: 3.25rem;
  line-height: 3.4rem;
  cursor: pointer;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame .difflower .tips {
  position: absolute;
  bottom: -2rem;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame .checkbox {
  font-size: 1.17rem;
  height: 1.33rem;
  line-height: 1.333rem;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame .checkbox .check {
  height: 1.33rem;
  width: 1.33rem;
  margin: 0;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame .checkbox span {
  display: inline-block;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  margin-left: 2rem;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame .checkbox a {
  color: #459cff;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame .wirteFrame .checkbox .tips {
  margin-top: 0.6rem;
}
.rightNav .rightNavFrame ul .rightNavRegister .rightNavRegisterFrame button {
  background: #1784ff;
  width: 21.25rem;
  height: 4rem;
  color: #fff;
  font-size: 1.83rem;
  font-family: SourceHanSansCN-Medium;
  border-radius: 0.33rem;
  border: none;
}
.rightNav .rightNavFrame ul .rightNavContact {
  position: relative;
}
.rightNav .rightNavFrame ul .rightNavContact .rightNavContactFrame {
  cursor: default;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(186, 210, 246, 0.28);
  border-radius: 1.67rem;
  position: absolute;
  right: 7.45rem;
  top: -6.5rem;
  width: 24.33rem;
  height: 22.33rem;
  padding: 3.33rem 5.2rem 4.5rem 3.92rem;
}
.rightNav .rightNavFrame ul .rightNavContact .rightNavContactFrame .title {
  font-size: 1.33rem;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #666666;
  position: relative;
}
.rightNav .rightNavFrame ul .rightNavContact .rightNavContactFrame .title .contactClose {
  position: absolute;
  top: -1.58rem;
  right: -3.5rem;
  width: 0.84rem;
  height: 0.84rem;
  cursor: pointer;
}
.rightNav .rightNavFrame ul .rightNavContact .rightNavContactFrame .title .contactClose img {
  width: 0.84rem;
  height: 0.84rem;
}
.rightNav .rightNavFrame ul .rightNavContact .rightNavContactFrame .title .contactTag {
  position: absolute;
  display: inline-block;
  right: -6.65rem;
  top: 8.08rem;
  border: 0.75rem solid;
  border-color: transparent transparent transparent white;
}
.rightNav .rightNavFrame ul .rightNavContact .rightNavContactFrame .title .phone {
  font-size: 2rem;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #357def;
  display: inline-block;
  margin-bottom: 0.75rem;
}
.rightNav .rightNavFrame ul .rightNavContact .rightNavContactFrame .title .time {
  font-size: 1.33rem;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #666666;
  display: inline-block;
  margin-bottom: 0.75rem;
}
.rightNav .rightNavFrame ul .rightNavContact .rightNavContactFrame .title .code img {
  width: 6.67rem;
  height: 6.67rem;
}
.rightNav .rightNavFrame ul .rightNavContact .rightNavContactFrame .title .code img:nth-of-type(1) {
  margin-right: 1.8rem;
}
.rightNav .rightNavFrame ul .rightNavLast {
  font-size: 0;
  width: 5.33rem;
  height: 5.33rem;
  text-align: center;
  margin: 0;
}
.rightNav .rightNavFrame ul .rightNavLast .default {
  width: 100%;
  height: 100%;
}
.rightNav .rightNavFrame ul .rightNavLast .default img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
